$sidebar-bg-color: $white !default;
$sidebar-color: $text !default;
$highlight-color: $primary !default;
$submenu-bg-color: $white-ter !default;
$submenu-bg-color-collapsed: $white !default;
$sidebar-width: unset !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";
