@import "~react-toastify/scss/main.scss";

.Toastify__toast--dark {
  background: $dark;
}
.Toastify__toast--default {
  background: $background;
}
.Toastify__toast--info {
  background: $info;
}
.Toastify__toast--success {
  background: $success;
}
.Toastify__toast--warning {
  background: $warning;
}
.Toastify__toast--error {
  background: $danger;
}
