@use "../../theme/variables.scss" as vars;

.swatch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid vars.$border;
}

.icon {
  z-index: 0 !important;
}
