@charset "utf-8";

$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

@import "../bulma-generated/generated-fallback.css"; // import fallbacks first, so they are overridden
@import "./variables.scss";
// bulma
@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/_all";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";

@import "./libs";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: 0;
  right: 0;
}

input,
textarea,
[contenteditable] {
  caret-color: $primary;
}

.navbar-burger {
  border: 0;

  &:focus {
    outline: none;
  }
}

.image-cover {
  object-fit: cover;
}

.is-width-100 {
  width: 100%;
}

.panel-block.media {
  margin-top: 0;
  border-top: none;
}

.media + .media {
  padding-top: 0.5rem;
}

.is-bottom-fixed-button {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;

  @include tablet {
    left: 50%;
    right: unset;
    bottom: 0;
    transform: translate(-50%);
  }

  .control {
    display: flex;
    flex-direction: column;

    button {
      margin: 10px;
    }
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.is-bordered {
  border: 1px solid $border-light;
  border-radius: $radius;
}

.is-flex-safe-width {
  min-width: 0;
  overflow: hidden;
  word-break: break-all;
}

.stripe-input {
  @extend .input;

  > div {
    flex: 1;
  }
}

.is-option-checkbox {
  @extend .pt-2;
  @extend .pb-2;
  border-bottom: 1px solid $border;

  &:last-of-type {
    border-bottom: none;
  }

  label {
    display: flex;
    flex: 1;
    align-items: center;
    word-break: break-all;
    min-width: 0;

    .option-price {
      @extend .ml-2;
      @extend .has-text-grey;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: end;
    }
  }
}

.has-ellipsis-if-long {
  min-width: 0;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  @extend .mr-2;
}

.modal {
  z-index: 9999999;
  &.is-fullscreen {
    .modal-card {
      max-height: unset;
      height: 100%;
      width: 100%;
    }
    .modal-card,
    .modal-card-head,
    .modal-card-foot {
      border-radius: 0px;
    }
  }
  &.no-scrollable {
    .modal-card-body {
      display: flex;
      flex-direction: column;
    }
  }
}

.card.is-shadowless {
  border: 1px solid $border;
  border-radius: $radius;

  .card-header {
    box-shadow: none;
  }
}

.modal-card-title {
  line-height: 1.3;
}

.border {
  border: 2px solid #4a4a4a;
  border-radius: 5px;
  margin: 0.2px;
  background-color: #2460aa40;
  text-align: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.has-long-text {
  white-space: pre-wrap;
}

.has-rounded-corners {
  border-radius: $card-radius;
  overflow: hidden;
}

.remove-parent-padding {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.has-background-primary-with-text {
  @extend .has-background-primary;
  @extend .has-text-white;
}

.bubble-light {
  @extend .has-rounded-corners;
  @extend .has-background-primary-light;
}

.bubble-left {
  @extend .p-3;
  @extend .has-background-light;

  border-radius: $card-radius * 3;
  border-top-left-radius: $card-radius;
}

.bubble-right {
  @extend .p-3;
  @extend .has-background-primary-with-text;

  border-radius: $card-radius * 3;
  border-top-right-radius: $card-radius;
}

.is-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.is-sticky-bottom {
  @extend .is-sticky;
  top: unset;
  bottom: 0;
}

.has-top-border {
  border-top-color: $border;
  border-top-style: solid;
  border-top-width: 1px;
}

.has-bottom-border {
  border-bottom-color: $border;
  border-bottom-style: dashed;
  border-bottom-width: 1px;
}

.is-disabled {
  opacity: 0.47;
}

.disabled-link {
  pointer-events: none;
  color: gray;
}

@media screen and (min-width: 701px) {
  .textResponsive {
    font-size: 20px;
  }
}
@media screen and (max-width: 700px) {
  .textResponsive {
    font-size: 15px;
  }
}
@media screen and (max-width: 525px) {
  .textResponsive {
    font-size: 12px;
  }
}
@media screen and (max-width: 450px) {
  .textResponsive {
    font-size: 10px;
  }
}
@media screen and (max-width: 395px) {
  .textResponsive {
    font-size: 9px;
  }
}
@media screen and (max-width: 320px) {
  .textResponsive {
    font-size: 8px;
  }
}
@media screen and (max-width: 280px) {
  .textResponsive {
    font-size: 7px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.is-max-fullhd {
  max-width: $fullhd !important;
}

.has-no-max {
  max-width: unset !important;
}

.is-scrollable-x {
  overflow-x: auto !important;
}

.is-flex {
  min-width: 0 !important;
}

.is-width-100 {
  width: 100%;
}
