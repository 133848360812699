@import "~@creativebulma/bulma-collapsible";

.is-collapsible {
  &[aria-expanded="false"] {
    padding: 0px;
  }
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.is-collapsible.is-active {
  .card-content {
    border-top: 1px solid $border-light;
  }
}
