
// sass variables
$danger: var(--danger)
$primary: var(--primary)
$success: var(--success)


// declared base css variables
#{":root"}
  --danger: rgb(210, 39, 48)
  --primary: rgb(36, 96, 170)
  --success: rgb(38, 158, 106)


// derived, generated css variables
#{":root"}
  --danger--color-invert: rgb(255, 255, 255)
  --danger--dark-color: rgb(207, 38, 47)
  --danger--light-color: rgb(252, 238, 239)

  --primary--color-invert: rgb(255, 255, 255)
  --primary--dark-color: rgb(44, 117, 206)
  --primary--light-color: rgb(238, 244, 251)

  --success--color-invert: rgb(255, 255, 255)
  --success--dark-color: rgb(40, 164, 110)
  --success--light-color: rgb(239, 251, 246)

