.button.is-primary {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary:hover,
.button.is-primary.is-hovered {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary:focus,
.button.is-primary.is-focused {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary:active,
.button.is-primary.is-active {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
  background-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted:hover,
.button.is-primary.is-inverted.is-hovered {
  background-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted[disabled],
fieldset[disabled] .button.is-primary.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-outlined {
  border-color: rgb(36, 96, 170);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-outlined:hover,
.button.is-primary.is-outlined.is-hovered,
.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined.is-focused {
  background-color: rgb(36, 96, 170);
  border-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  border-color: rgb(36, 96, 170);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted.is-outlined:hover,
.button.is-primary.is-inverted.is-outlined.is-hovered,
.button.is-primary.is-inverted.is-outlined:focus,
.button.is-primary.is-inverted.is-outlined.is-focused {
  background-color: rgb(255, 255, 255);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-inverted.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-light {
  background-color: rgb(238, 244, 251);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-light:hover,
.button.is-primary.is-light.is-hovered {
  background-color: rgb(238, 244, 251);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-primary.is-light:active,
.button.is-primary.is-light.is-active {
  background-color: rgb(238, 244, 251);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success:hover,
.button.is-success.is-hovered {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success:focus,
.button.is-success.is-focused {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success:active,
.button.is-success.is-active {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success[disabled],
fieldset[disabled] .button.is-success {
  background-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted:hover,
.button.is-success.is-inverted.is-hovered {
  background-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted[disabled],
fieldset[disabled] .button.is-success.is-inverted {
  background-color: rgb(255, 255, 255);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-outlined {
  border-color: rgb(38, 158, 106);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-outlined:hover,
.button.is-success.is-outlined.is-hovered,
.button.is-success.is-outlined:focus,
.button.is-success.is-outlined.is-focused {
  background-color: rgb(38, 158, 106);
  border-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-outlined[disabled],
fieldset[disabled] .button.is-success.is-outlined {
  border-color: rgb(38, 158, 106);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted.is-outlined:hover,
.button.is-success.is-inverted.is-outlined.is-hovered,
.button.is-success.is-inverted.is-outlined:focus,
.button.is-success.is-inverted.is-outlined.is-focused {
  background-color: rgb(255, 255, 255);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-inverted.is-outlined[disabled],
fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-light {
  background-color: rgb(239, 251, 246);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-light:hover,
.button.is-success.is-light.is-hovered {
  background-color: rgb(239, 251, 246);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-success.is-light:active,
.button.is-success.is-light.is-active {
  background-color: rgb(239, 251, 246);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger,
.is-danger-input .button.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger:hover,
.is-danger-input .button.rc-time-picker-input:hover,
.button.is-danger.is-hovered,
.is-danger-input .button.is-hovered.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger:focus,
.is-danger-input .button.rc-time-picker-input:focus,
.button.is-danger.is-focused,
.is-danger-input .button.is-focused.rc-time-picker-input {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger:active,
.is-danger-input .button.rc-time-picker-input:active,
.button.is-danger.is-active,
.is-danger-input .button.is-active.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger[disabled],
.is-danger-input .button[disabled].rc-time-picker-input,
fieldset[disabled] .button.is-danger,
fieldset[disabled] .is-danger-input .button.rc-time-picker-input,
.is-danger-input fieldset[disabled] .button.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted,
.is-danger-input .button.is-inverted.rc-time-picker-input {
  background-color: rgb(255, 255, 255);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted:hover,
.is-danger-input .button.is-inverted.rc-time-picker-input:hover,
.button.is-danger.is-inverted.is-hovered,
.is-danger-input .button.is-inverted.is-hovered.rc-time-picker-input {
  background-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted[disabled],
.is-danger-input .button.is-inverted[disabled].rc-time-picker-input,
fieldset[disabled] .button.is-danger.is-inverted,
fieldset[disabled] .is-danger-input .button.is-inverted.rc-time-picker-input,
.is-danger-input fieldset[disabled] .button.is-inverted.rc-time-picker-input {
  background-color: rgb(255, 255, 255);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-outlined,
.is-danger-input .button.is-outlined.rc-time-picker-input {
  border-color: rgb(210, 39, 48);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-outlined:hover,
.is-danger-input .button.is-outlined.rc-time-picker-input:hover,
.button.is-danger.is-outlined.is-hovered,
.is-danger-input .button.is-outlined.is-hovered.rc-time-picker-input,
.button.is-danger.is-outlined:focus,
.is-danger-input .button.is-outlined.rc-time-picker-input:focus,
.button.is-danger.is-outlined.is-focused,
.is-danger-input .button.is-outlined.is-focused.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  border-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-outlined[disabled],
.is-danger-input .button.is-outlined[disabled].rc-time-picker-input,
fieldset[disabled] .button.is-danger.is-outlined,
fieldset[disabled] .is-danger-input .button.is-outlined.rc-time-picker-input,
.is-danger-input fieldset[disabled] .button.is-outlined.rc-time-picker-input {
  border-color: rgb(210, 39, 48);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted.is-outlined,
.is-danger-input .button.is-inverted.is-outlined.rc-time-picker-input {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted.is-outlined:hover,
.is-danger-input .button.is-inverted.is-outlined.rc-time-picker-input:hover,
.button.is-danger.is-inverted.is-outlined.is-hovered,
.is-danger-input .button.is-inverted.is-outlined.is-hovered.rc-time-picker-input,
.button.is-danger.is-inverted.is-outlined:focus,
.is-danger-input .button.is-inverted.is-outlined.rc-time-picker-input:focus,
.button.is-danger.is-inverted.is-outlined.is-focused,
.is-danger-input .button.is-inverted.is-outlined.is-focused.rc-time-picker-input {
  background-color: rgb(255, 255, 255);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-inverted.is-outlined[disabled],
.is-danger-input .button.is-inverted.is-outlined[disabled].rc-time-picker-input,
fieldset[disabled] .button.is-danger.is-inverted.is-outlined,
fieldset[disabled] .is-danger-input .button.is-inverted.is-outlined.rc-time-picker-input,
.is-danger-input fieldset[disabled] .button.is-inverted.is-outlined.rc-time-picker-input {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-light,
.is-danger-input .button.is-light.rc-time-picker-input {
  background-color: rgb(252, 238, 239);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-light:hover,
.is-danger-input .button.is-light.rc-time-picker-input:hover,
.button.is-danger.is-light.is-hovered,
.is-danger-input .button.is-light.is-hovered.rc-time-picker-input {
  background-color: rgb(252, 238, 239);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.button.is-danger.is-light:active,
.is-danger-input .button.is-light.rc-time-picker-input:active,
.button.is-danger.is-light.is-active,
.is-danger-input .button.is-light.is-active.rc-time-picker-input {
  background-color: rgb(252, 238, 239);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-primary {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-primary.is-light {
  background-color: rgb(238, 244, 251);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-success {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-success.is-light {
  background-color: rgb(239, 251, 246);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-danger,
.is-danger-input .notification.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.notification.is-danger.is-light,
.is-danger-input .notification.is-light.rc-time-picker-input {
  background-color: rgb(252, 238, 239);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-primary::-webkit-progress-value {
  background-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-primary::-moz-progress-bar {
  background-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-primary::-ms-fill {
  background-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-success::-webkit-progress-value {
  background-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-success::-moz-progress-bar {
  background-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-success::-ms-fill {
  background-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-danger::-webkit-progress-value,
.is-danger-input .progress.rc-time-picker-input::-webkit-progress-value {
  background-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-danger::-moz-progress-bar,
.is-danger-input .progress.rc-time-picker-input::-moz-progress-bar {
  background-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.progress.is-danger::-ms-fill,
.is-danger-input .progress.rc-time-picker-input::-ms-fill {
  background-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table td.is-primary,
.table th.is-primary {
  background-color: rgb(36, 96, 170);
  border-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table td.is-success,
.table th.is-success {
  background-color: rgb(38, 158, 106);
  border-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table td.is-danger,
.table .is-danger-input td.rc-time-picker-input,
.is-danger-input .table td.rc-time-picker-input,
.table th.is-danger,
.table .is-danger-input th.rc-time-picker-input,
.is-danger-input .table th.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  border-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table td.is-selected,
.table th.is-selected {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table tr.is-selected {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.table tr.is-selected td,
.table tr.is-selected th {
  border-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-primary {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-primary.is-light {
  background-color: rgb(238, 244, 251);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-success {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-success.is-light {
  background-color: rgb(239, 251, 246);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-danger,
.is-danger-input .tag.rc-time-picker-input:not(body) {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.tag:not(body).is-danger.is-light,
.is-danger-input .tag.rc-time-picker-input:not(body).is-light {
  background-color: rgb(252, 238, 239);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.is-primary.textarea,
.is-primary.input,
.is-primary.stripe-input,
.rc-time-picker input.is-primary {
  border-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.is-success.textarea,
.is-success.input,
.is-success.stripe-input,
.rc-time-picker input.is-success {
  border-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.is-danger.textarea,
.is-danger-input .textarea.rc-time-picker-input,
.is-danger.input,
.is-danger.stripe-input,
.is-danger-input .input.rc-time-picker-input,
.is-danger-input .rc-time-picker-input.stripe-input,
.rc-time-picker input.is-danger,
.rc-time-picker .is-danger-input input.rc-time-picker-input,
.is-danger-input .rc-time-picker input.rc-time-picker-input {
  border-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-primary:not(:hover)::after {
  border-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-primary select {
  border-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-primary select:hover,
.select.is-primary select.is-hovered {
  border-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-success:not(:hover)::after {
  border-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-success select {
  border-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-success select:hover,
.select.is-success select.is-hovered {
  border-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-danger:not(:hover)::after,
.is-danger-input .select.rc-time-picker-input:not(:hover)::after {
  border-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-danger select,
.is-danger-input .select.rc-time-picker-input select {
  border-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.select.is-danger select:hover,
.is-danger-input .select.rc-time-picker-input select:hover,
.select.is-danger select.is-hovered,
.is-danger-input .select.rc-time-picker-input select.is-hovered {
  border-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-primary .file-cta {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-primary:hover .file-cta,
.file.is-primary.is-hovered .file-cta {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-primary:focus .file-cta,
.file.is-primary.is-focused .file-cta {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-primary:active .file-cta,
.file.is-primary.is-active .file-cta {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-success .file-cta {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-success:hover .file-cta,
.file.is-success.is-hovered .file-cta {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-success:focus .file-cta,
.file.is-success.is-focused .file-cta {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-success:active .file-cta,
.file.is-success.is-active .file-cta {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-danger .file-cta,
.is-danger-input .file.rc-time-picker-input .file-cta {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-danger:hover .file-cta,
.is-danger-input .file.rc-time-picker-input:hover .file-cta,
.file.is-danger.is-hovered .file-cta,
.is-danger-input .file.is-hovered.rc-time-picker-input .file-cta {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-danger:focus .file-cta,
.is-danger-input .file.rc-time-picker-input:focus .file-cta,
.file.is-danger.is-focused .file-cta,
.is-danger-input .file.is-focused.rc-time-picker-input .file-cta {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.file.is-danger:active .file-cta,
.is-danger-input .file.rc-time-picker-input:active .file-cta,
.file.is-danger.is-active .file-cta,
.is-danger-input .file.is-active.rc-time-picker-input .file-cta {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.help.is-primary {
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.help.is-success {
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.help.is-danger,
.is-danger-input .help.rc-time-picker-input {
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-primary {
  background-color: rgb(238, 244, 251);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-primary .message-header {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-primary .message-body {
  border-color: rgb(36, 96, 170);
  color: rgb(44, 117, 206);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-success {
  background-color: rgb(239, 251, 246);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-success .message-header {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-success .message-body {
  border-color: rgb(38, 158, 106);
  color: rgb(40, 164, 110);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-danger,
.is-danger-input .message.rc-time-picker-input {
  background-color: rgb(252, 238, 239);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-danger .message-header,
.is-danger-input .message.rc-time-picker-input .message-header {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.message.is-danger .message-body,
.is-danger-input .message.rc-time-picker-input .message-body {
  border-color: rgb(210, 39, 48);
  color: rgb(207, 38, 47);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-primary {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-primary .navbar-brand > .navbar-item,
.navbar.is-primary .navbar-brand .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus,
.navbar.is-primary .navbar-brand > a.navbar-item:hover,
.navbar.is-primary .navbar-brand > a.navbar-item.is-active,
.navbar.is-primary .navbar-brand .navbar-link:focus,
.navbar.is-primary .navbar-brand .navbar-link:hover,
.navbar.is-primary .navbar-brand .navbar-link.is-active {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-primary .navbar-brand .navbar-link::after {
  border-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-primary .navbar-burger {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (min-width: 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item,
  .navbar.is-primary .navbar-start .navbar-link,
  .navbar.is-primary .navbar-end > .navbar-item,
  .navbar.is-primary .navbar-end .navbar-link {
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus,
  .navbar.is-primary .navbar-start > a.navbar-item:hover,
  .navbar.is-primary .navbar-start > a.navbar-item.is-active,
  .navbar.is-primary .navbar-start .navbar-link:focus,
  .navbar.is-primary .navbar-start .navbar-link:hover,
  .navbar.is-primary .navbar-start .navbar-link.is-active,
  .navbar.is-primary .navbar-end > a.navbar-item:focus,
  .navbar.is-primary .navbar-end > a.navbar-item:hover,
  .navbar.is-primary .navbar-end > a.navbar-item.is-active,
  .navbar.is-primary .navbar-end .navbar-link:focus,
  .navbar.is-primary .navbar-end .navbar-link:hover,
  .navbar.is-primary .navbar-end .navbar-link.is-active {
    background-color: rgb(36, 96, 170);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-primary .navbar-start .navbar-link::after,
  .navbar.is-primary .navbar-end .navbar-link::after {
    border-color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: rgb(36, 96, 170);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    background-color: rgb(36, 96, 170);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.navbar.is-success {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-success .navbar-brand > .navbar-item,
.navbar.is-success .navbar-brand .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus,
.navbar.is-success .navbar-brand > a.navbar-item:hover,
.navbar.is-success .navbar-brand > a.navbar-item.is-active,
.navbar.is-success .navbar-brand .navbar-link:focus,
.navbar.is-success .navbar-brand .navbar-link:hover,
.navbar.is-success .navbar-brand .navbar-link.is-active {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-success .navbar-brand .navbar-link::after {
  border-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-success .navbar-burger {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (min-width: 1024px) {
  .navbar.is-success .navbar-start > .navbar-item,
  .navbar.is-success .navbar-start .navbar-link,
  .navbar.is-success .navbar-end > .navbar-item,
  .navbar.is-success .navbar-end .navbar-link {
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus,
  .navbar.is-success .navbar-start > a.navbar-item:hover,
  .navbar.is-success .navbar-start > a.navbar-item.is-active,
  .navbar.is-success .navbar-start .navbar-link:focus,
  .navbar.is-success .navbar-start .navbar-link:hover,
  .navbar.is-success .navbar-start .navbar-link.is-active,
  .navbar.is-success .navbar-end > a.navbar-item:focus,
  .navbar.is-success .navbar-end > a.navbar-item:hover,
  .navbar.is-success .navbar-end > a.navbar-item.is-active,
  .navbar.is-success .navbar-end .navbar-link:focus,
  .navbar.is-success .navbar-end .navbar-link:hover,
  .navbar.is-success .navbar-end .navbar-link.is-active {
    background-color: rgb(38, 158, 106);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-success .navbar-start .navbar-link::after,
  .navbar.is-success .navbar-end .navbar-link::after {
    border-color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: rgb(38, 158, 106);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    background-color: rgb(38, 158, 106);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.navbar.is-danger,
.is-danger-input .navbar.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-danger .navbar-brand > .navbar-item,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand > .navbar-item,
.navbar.is-danger .navbar-brand .navbar-link,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand > a.navbar-item:focus,
.navbar.is-danger .navbar-brand > a.navbar-item:hover,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand > a.navbar-item:hover,
.navbar.is-danger .navbar-brand > a.navbar-item.is-active,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand > a.navbar-item.is-active,
.navbar.is-danger .navbar-brand .navbar-link:focus,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand .navbar-link:focus,
.navbar.is-danger .navbar-brand .navbar-link:hover,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand .navbar-link:hover,
.navbar.is-danger .navbar-brand .navbar-link.is-active,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand .navbar-link.is-active {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-danger .navbar-brand .navbar-link::after,
.is-danger-input .navbar.rc-time-picker-input .navbar-brand .navbar-link::after {
  border-color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.navbar.is-danger .navbar-burger,
.is-danger-input .navbar.rc-time-picker-input .navbar-burger {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (min-width: 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start > .navbar-item,
  .navbar.is-danger .navbar-start .navbar-link,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start .navbar-link,
  .navbar.is-danger .navbar-end > .navbar-item,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end > .navbar-item,
  .navbar.is-danger .navbar-end .navbar-link,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end .navbar-link {
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start > a.navbar-item:focus,
  .navbar.is-danger .navbar-start > a.navbar-item:hover,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start > a.navbar-item:hover,
  .navbar.is-danger .navbar-start > a.navbar-item.is-active,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start > a.navbar-item.is-active,
  .navbar.is-danger .navbar-start .navbar-link:focus,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start .navbar-link:focus,
  .navbar.is-danger .navbar-start .navbar-link:hover,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start .navbar-link:hover,
  .navbar.is-danger .navbar-start .navbar-link.is-active,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start .navbar-link.is-active,
  .navbar.is-danger .navbar-end > a.navbar-item:focus,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end > a.navbar-item:focus,
  .navbar.is-danger .navbar-end > a.navbar-item:hover,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end > a.navbar-item:hover,
  .navbar.is-danger .navbar-end > a.navbar-item.is-active,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end > a.navbar-item.is-active,
  .navbar.is-danger .navbar-end .navbar-link:focus,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end .navbar-link:focus,
  .navbar.is-danger .navbar-end .navbar-link:hover,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end .navbar-link:hover,
  .navbar.is-danger .navbar-end .navbar-link.is-active,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end .navbar-link.is-active {
    background-color: rgb(210, 39, 48);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-danger .navbar-start .navbar-link::after,
  .is-danger-input .navbar.rc-time-picker-input .navbar-start .navbar-link::after,
  .navbar.is-danger .navbar-end .navbar-link::after,
  .is-danger-input .navbar.rc-time-picker-input .navbar-end .navbar-link::after {
    border-color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link,
  .is-danger-input .navbar.rc-time-picker-input .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link,
  .is-danger-input .navbar.rc-time-picker-input .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link,
  .is-danger-input .navbar.rc-time-picker-input .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: rgb(210, 39, 48);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active,
  .is-danger-input .navbar.rc-time-picker-input .navbar-dropdown a.navbar-item.is-active {
    background-color: rgb(210, 39, 48);
    color: rgb(255, 255, 255);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.panel.is-primary .panel-heading {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-success .panel-heading {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-danger .panel-heading,
.is-danger-input .panel.rc-time-picker-input .panel-heading {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-danger .panel-tabs a.is-active,
.is-danger-input .panel.rc-time-picker-input .panel-tabs a.is-active {
  border-bottom-color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.panel.is-danger .panel-block.is-active .panel-icon,
.is-danger-input .panel.rc-time-picker-input .panel-block.is-active .panel-icon {
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .title {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .subtitle {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .subtitle a:not(.button),
.hero.is-primary .subtitle strong {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: rgb(36, 96, 170);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.hero.is-primary .navbar-item,
.hero.is-primary .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary a.navbar-item:hover,
.hero.is-primary a.navbar-item.is-active,
.hero.is-primary .navbar-link:hover,
.hero.is-primary .navbar-link.is-active {
  background-color: rgb(36, 96, 170);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .tabs a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .tabs.is-boxed a,
.hero.is-primary .tabs.is-toggle a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-primary .tabs.is-boxed li.is-active a,
.hero.is-primary .tabs.is-boxed li.is-active a:hover,
.hero.is-primary .tabs.is-toggle li.is-active a,
.hero.is-primary .tabs.is-toggle li.is-active a:hover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .title {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .subtitle {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .subtitle a:not(.button),
.hero.is-success .subtitle strong {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: rgb(38, 158, 106);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.hero.is-success .navbar-item,
.hero.is-success .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success a.navbar-item:hover,
.hero.is-success a.navbar-item.is-active,
.hero.is-success .navbar-link:hover,
.hero.is-success .navbar-link.is-active {
  background-color: rgb(38, 158, 106);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .tabs a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .tabs.is-boxed a,
.hero.is-success .tabs.is-toggle a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-success .tabs.is-boxed li.is-active a,
.hero.is-success .tabs.is-boxed li.is-active a:hover,
.hero.is-success .tabs.is-toggle li.is-active a,
.hero.is-success .tabs.is-toggle li.is-active a:hover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger,
.is-danger-input .hero.rc-time-picker-input {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .title,
.is-danger-input .hero.rc-time-picker-input .title {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .subtitle,
.is-danger-input .hero.rc-time-picker-input .subtitle {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .subtitle a:not(.button),
.is-danger-input .hero.rc-time-picker-input .subtitle a:not(.button),
.hero.is-danger .subtitle strong,
.is-danger-input .hero.rc-time-picker-input .subtitle strong {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu,
  .is-danger-input .hero.rc-time-picker-input .navbar-menu {
    background-color: rgb(210, 39, 48);
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
}

.hero.is-danger .navbar-item,
.is-danger-input .hero.rc-time-picker-input .navbar-item,
.hero.is-danger .navbar-link,
.is-danger-input .hero.rc-time-picker-input .navbar-link {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger a.navbar-item:hover,
.is-danger-input .hero.rc-time-picker-input a.navbar-item:hover,
.hero.is-danger a.navbar-item.is-active,
.is-danger-input .hero.rc-time-picker-input a.navbar-item.is-active,
.hero.is-danger .navbar-link:hover,
.is-danger-input .hero.rc-time-picker-input .navbar-link:hover,
.hero.is-danger .navbar-link.is-active,
.is-danger-input .hero.rc-time-picker-input .navbar-link.is-active {
  background-color: rgb(210, 39, 48);
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .tabs a,
.is-danger-input .hero.rc-time-picker-input .tabs a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .tabs.is-boxed a,
.is-danger-input .hero.rc-time-picker-input .tabs.is-boxed a,
.hero.is-danger .tabs.is-toggle a,
.is-danger-input .hero.rc-time-picker-input .tabs.is-toggle a {
  color: rgb(255, 255, 255);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.hero.is-danger .tabs.is-boxed li.is-active a,
.is-danger-input .hero.rc-time-picker-input .tabs.is-boxed li.is-active a,
.hero.is-danger .tabs.is-boxed li.is-active a:hover,
.hero.is-danger .tabs.is-toggle li.is-active a,
.is-danger-input .hero.rc-time-picker-input .tabs.is-toggle li.is-active a,
.hero.is-danger .tabs.is-toggle li.is-active a:hover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  color: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.Toastify__toast--success {
  background: rgb(38, 158, 106);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.Toastify__toast--error {
  background: rgb(210, 39, 48);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

input,
textarea,
[contenteditable] {
  caret-color: rgb(36, 96, 170);
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}