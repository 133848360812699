.card {
  width: 300px;
  @media screen and (max-width: 700px) {
    width: 80vw;
  }

  figure {
    img {
      object-fit: cover;
      object-position: center;
    }
  }
}

.cardDisabled {
  img {
    filter: brightness(50%);
  }
}
